import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

const StyledLink = styled(Link)`
  font-family: Comfortaa, sans-serif;
  font-size: 24px;
  text-decoration: none;
  color: aqua;
  &[aria-current] {
    font-size: 60px;
  }
`;

const HorizontalName = styled.h1`
  font-size: 20vw;
  color: #fff;
`
const VerticalName = styled.span`
  text-orientiation: vertical;
`
        // <HorizontalName>
        //   ELLE
        //   <VerticalName>DEE</VerticalName>
        // </HorizontalName>
const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    // Get all "navbar-burger" elements
   const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    // Check if there are any navbar burgers
   if ($navbarBurgers.length > 0) {

     // Add a click event on each of them
     $navbarBurgers.forEach( el => {
       el.addEventListener('click', () => {

         // Get the target from the "data-target" attribute
         const target = el.dataset.target;
         const $target = document.getElementById(target);

         // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
         el.classList.toggle('is-active');
         $target.classList.toggle('is-active');

       });
     });
   }
 }

 render() {
   return (
    <nav role="navigation" aria-label="main-navigation">
      <div>
        <div>
          <StyledLink to="/" title="Logo">
            {/* <img src={logo} alt="Kaldi" style={{ width: '88px' }} /> */}
          </StyledLink>
          {/* Hamburger menu */}
          <div data-target="navMenu">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="navMenu">
        <div>
          <StyledLink to="/about">
            About
          </StyledLink>
          <StyledLink to="/blogs">
            Blogs
          </StyledLink>
          <StyledLink to="/products">
            Products
          </StyledLink>
          <StyledLink to="/contact">
            Contact
          </StyledLink>
          <StyledLink to="/contact/examples">
            Form Examples
          </StyledLink>
        </div>
        </div>
      </div>
    </nav>
  )}
}

export default Navbar
