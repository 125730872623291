import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { StaticQuery, graphql } from "gatsby"
import Navbar from '../components/Navbar'
import StyledBody from './StyledBody'
import GlobalStyle from './GlobalStyle'

const PadTopNav = styled.div`
  ${({ hasPadding }) => hasPadding && 'padding-top: calc(4vw + 32px);'}
  display: flex;
  flex-direction: column;
  ${({ autoHeight }) => autoHeight ? '' : 'height: 100vh;'}
`

const TemplateWrapper = ({ children, hasPadding, style, autoHeight }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
          site {
            siteMetadata {
              title,
              description,
            }
          }
        }
    `}
    render={data => (
      <StyledBody id="body">
        <Helmet>
          <html lang="en" />
          <title>{data.site.siteMetadata.title}</title>
          <meta name="description" content={data.site.siteMetadata.description} />

          <link rel="apple-touch-icon" sizes="180x180" href="/img/apple-touch-icon.png" />
	        <link rel="icon" type="image/png" href="/img/favicon-32x32.png" sizes="32x32" />
	        <link rel="icon" type="image/png" href="/img/favicon-16x16.png" sizes="16x16" />

	        <link rel="mask-icon" href="/img/safari-pinned-tab.svg" color="#ff4400" />
	        <meta name="theme-color" content="#fff" />

	        <meta property="og:type" content="business.business" />
          <meta property="og:title" content={data.site.siteMetadata.title} />
          <meta property="og:url" content="/" />
          <meta property="og:image" content="img/site_preview.png" />
        </Helmet>
        <GlobalStyle />
        <PadTopNav style={style} autoHeight={autoHeight} hasPadding={hasPadding}>{children}</PadTopNav>
      </StyledBody>
    )}
  />
)

export default TemplateWrapper
